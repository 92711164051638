import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import { Alert, AlertTitle, AlertDescription } from '../../components/ui/alert'
import { AlertCircle } from 'lucide-react'

const ProductBilling = ({ options, setOptions, product }) => {
  return (
    <>
      <div className="flex flex-col md:flex-row gap-4">
        <BillingScheduleItem
          title="Hourly"
          value="hourly"
          unit="hour"
          price={product?.pricing.hourly}
          stocklevel={product?.stocklevel_hourly}
          options={options}
          setOptions={setOptions}
        />
        <BillingScheduleItem
          title="Monthly"
          value="monthly"
          unit="month"
          price={product?.pricing.monthly}
          stocklevel={product?.stocklevel}
          options={options}
          setOptions={setOptions}
        />
        <BillingScheduleItem
          title="6 Months"
          value="semiannually"
          unit="6 months"
          price={product?.pricing.semiannually}
          stocklevel={product?.stocklevel}
          options={options}
          setOptions={setOptions}
        />
      </div>
      {options.billingcycle === 'hourly' && (
        <Alert variant="destructive" className="mt-6">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Spot Instance</AlertTitle>
          <AlertDescription>
            Hourly services are spot availability and subject to termination. If
            you are running a mission critical workload, please consider an
            extended billing cycle.
          </AlertDescription>
        </Alert>
      )}
    </>
  )
}

const BillingScheduleItem = ({
  title,
  price,
  options,
  setOptions,
  value,
  unit,
  stocklevel,
}) => {
  let active = options.billingcycle === value

  const setActive = () => {
    if (!price || !stocklevel) return
    setOptions({ ...options, billingcycle: value })
  }

  return (
    <Card
      className={'w-full' + (active ? ' bg-muted' : '')}
      onClick={setActive}
    >
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent>
        {!!stocklevel && !!price && (
          <h2>
            ${price?.toFixed(2)} / {unit}
          </h2>
        )}
        {(!stocklevel || !price) && <h2>Out of stock</h2>}
      </CardContent>
    </Card>
  )
}

export default ProductBilling
