import DashboardLayout from '../../components/DashboardLayout'
import React, { useState } from 'react'
import SSHKeys from './SSHKeys'
import APIKeys from './APIKeys' // Import the new APIKeys component
import { useForm } from 'react-hook-form'
import api from '../../api/api'
import { Input } from '../../components/ui/input'
import { Button } from '../../components/ui/button'
import { Icons } from '../../components/ui/icon'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from '../../components/ui/use-toast'
import iso3166 from 'iso-3166-1'

const countryCodes = iso3166.all()

const Settings = () => {
  return (
    <DashboardLayout
      page={'Settings'}
      crumbs={[
        { text: 'Dashboard', href: '/home' },
        { text: 'Settings', href: '/settings' },
      ]}
      content={
        <div className="space-y-8">
          <SSHKeys />
          <APIKeys /> {/* Add the APIKeys component here */}
          <UserInfo />
        </div>
      }
    />
  )
}

// Rest of the code remains unchanged

const UserInfo = () => {
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const formSchema = z.object({
    firstname: z.string().min(1, { message: 'First name is required.' }),
    lastname: z.string().min(1, { message: 'Last name is required.' }),
    companyname: z.string(),
    address1: z.string().min(1, { message: 'Address 1 is required.' }),
    address2: z.string(),
    city: z.string().min(1, { message: 'City is required.' }),
    state: z.string(),
    postcode: z.string().min(1, { message: 'Postal code is required.' }),
    country: z.string().min(1, { message: 'Country is required.' }),
    phonenumber: z.string().min(1, { message: 'Phone number is required.' }),
  })

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: async () => {
      setLoading(true)
      let { user } = await api({
        method: 'GET',
        url: '/user',
      })
      setLoading(false)
      return user
    },
  })

  const onSubmit = (data) => {
    if (submitting) return
    setSubmitting(true)
    api(
      {
        method: 'PATCH',
        url: '/user',
        data,
      },
      (data) => {
        setSubmitting(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description:
              data?.message ||
              'An error occurred while updating user information.',
            variant: 'destructive',
          })
        }
        toast({
          title: 'Success',
          description: 'User information updated successfully.',
        })
      },
    )
  }

  if (loading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>User Information</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center justify-center h-40">
            <Icons.spinner className="h-9 w-9 animate-spin" />
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>User Information</CardTitle>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="firstname"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>First Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastname"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Last Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="companyname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Company Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="address1"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address 1</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="address2"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address 2</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>City</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="state"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>State</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="postcode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Postal Code</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Country</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select a country" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Countries</SelectLabel>
                            {countryCodes.map((country) => (
                              <SelectItem
                                key={country.alpha2}
                                value={country.alpha2}
                              >
                                {country.country}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="phonenumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone Number</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" loading={submitting} className="w-fit">
              Save Changes
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  )
}

export default Settings
