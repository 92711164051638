import React, { useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { icons } from 'lucide-react'
import { motion } from 'framer-motion'
import LogoFull from '../assets/logo_full.svg'
import { useAuth } from '../hooks/useAuth.js'

const items = [
  {
    name: 'Home',
    path: '/home',
    icon: 'Home',
    auth: true,
  },
  {
    name: 'Order',
    path: '/order',
    icon: 'ShoppingCart',
  },
  {
    name: 'Services',
    path: '/services',
    icon: 'Server',
    auth: true,
  },
  {
    name: 'Support',
    path: '/support',
    icon: 'MessageCircleQuestion',
    auth: true,
  },
  // Direct link to create page for non-authed users
  {
    name: 'Support',
    path: '/support/ticketCreate',
    icon: 'MessageCircleQuestion',
    auth: false,
  },
  {
    name: 'Billing',
    path: '/billing',
    icon: 'CreditCard',
    auth: true,
  },
  {
    name: 'API',
    path: 'https://docs.puregpu.com',
    icon: 'Code',
  },
  {
    name: 'Settings',
    path: '/settings',
    icon: 'Settings',
    auth: true,
  },
  {
    name: 'Logout',
    path: '/logout',
    icon: 'LogOut',
    auth: true,
  },
  {
    name: 'Login',
    path: '/login',
    icon: 'LogIn',
    auth: false,
  },
  {
    name: 'Register',
    path: '/register',
    icon: 'UserPlus',
    auth: false,
  },
]

const SidebarComponent = ({ page, isOpen, toggleSidebar }) => {
  const location = useLocation()
  const { isAuthed } = useAuth()
  const sidebarRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        toggleSidebar(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, toggleSidebar])

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={() => toggleSidebar(false)}
        />
      )}
      <motion.div
        ref={sidebarRef}
        className={
          'fixed top-0 left-0 h-full w-64 bg-white shadow-lg z-50 md:static md:shadow-none md:w-auto md:h-auto md:block [--initial:-100%] md:[--initial:0]'
        }
        initial={{ x: 'var(--initial)' }}
        animate={{ x: isOpen ? 0 : 'var(--initial)' }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex h-20 items-center border-b px-4">
            <Link
              href="/home"
              className="flex items-center gap-2 font-semibold"
            >
              <img src={LogoFull} alt="PureGPU" className="h-9" />
            </Link>
          </div>
          <div className="flex-1">
            <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
              {items.map((item) => {
                if (typeof item.auth === 'boolean' && isAuthed !== item.auth) {
                  return null
                }

                let Icon = icons[item.icon]
                const isActive = location.pathname === item.path
                return (
                  <Link
                    to={item.path}
                    key={item.name}
                    className={`flex text-[1rem] my-1 py-3 items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                      isActive
                        ? 'bg-muted text-primary'
                        : 'text-muted-foreground hover:text-primary'
                    }`}
                  >
                    <Icon className="h-5 w-5" />
                    {item.name}
                  </Link>
                )
              })}
            </nav>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default SidebarComponent
