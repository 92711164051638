import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { toast } from '../../components/ui/use-toast'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog'
import { Button } from '../../components/ui/button'
import { UploadIcon, TrashIcon, PlusIcon } from 'lucide-react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form'
import { Input } from '../../components/ui/input'
import { useForm } from 'react-hook-form'
import { Label } from '../../components/ui/label'
import { RadioGroup, RadioGroupItem } from '../../components/ui/radio-group'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select'

const CustomISOButton = ({ service, server }) => {
  const [loading, setLoading] = useState(false)
  const [isoList, setIsoList] = useState([])
  const [open, setOpen] = useState(false)
  const [addIsoOpen, setAddIsoOpen] = useState(false)
  const form = useForm()
  const { handleSubmit, control } = form

  const [bootOrder, setBootOrder] = useState('cda') // Default value is 'cda'
  const [primaryISO, setPrimaryISO] = useState('')
  const [secondaryISO, setSecondaryISO] = useState('')

  useEffect(() => {
    if (open) {
      fetchIsoList()
      fetchBootOrderAndIsos() // Fetch the boot order and ISOs when the modal opens
    }
  }, [open])

  const fetchIsoList = async () => {
    setLoading(true)
    try {
      const response = await api({
        method: 'GET',
        url: '/isos',
      })
      if (response.result === 'success') {
        setIsoList(response.data.isos || [])
      } else {
        toast({
          title: 'Error',
          description: response.message,
          variant: 'destructive',
        })
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch ISOs',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  // Add this function to fetch boot order and mounted ISOs
  const fetchBootOrderAndIsos = async () => {
    setLoading(true)
    try {
      const response = await api({
        method: 'GET',
        url: `/server/${service.id}/bootOrderAndIsos`,
      })
      if (response.result === 'success') {
        setBootOrder(response.bootOrder || 'cda')
        setPrimaryISO(response.primaryISO || '')
        setSecondaryISO(response.secondaryISO || '')
      } else {
        toast({
          title: 'Error',
          description: response.message || 'Failed to fetch boot order and ISOs',
          variant: 'destructive',
        })
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch boot order and ISOs',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  const onAddIso = async (data) => {
    setLoading(true)
    try {
      const response = await api({
        method: 'POST',
        url: '/isos',
        data: {
          url: data.url,
        },
      })
      if (response.result === 'success') {
        toast({
          title: 'Success',
          description: 'ISO added successfully',
        })
        setAddIsoOpen(false)
        fetchIsoList()
      } else {
        toast({
          title: 'Error',
          description: response.message,
          variant: 'destructive',
        })
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to add ISO',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  const onDeleteIso = async (iso) => {
    if (!window.confirm('Are you sure you want to delete this ISO?')) return
    setLoading(true)
    try {
      const response = await api({
        method: 'DELETE',
        url: '/isos',
        data: {
          filename: iso,
        },
      })
      if (response.result === 'success') {
        toast({
          title: 'Success',
          description: 'ISO deleted successfully',
        })
        fetchIsoList()
      } else {
        toast({
          title: 'Error',
          description: response.message,
          variant: 'destructive',
        })
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete ISO',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  // Function to set boot order
  const handleSetBootOrder = async () => {
    if (!bootOrder) {
      toast({
        title: 'Error',
        description: 'Please select a boot order',
        variant: 'destructive',
      })
      return
    }
    setLoading(true)
    try {
      const response = await api({
        method: 'POST',
        url: `/server/${service.id}/setBootOrder`,
        data: {
          order: bootOrder,
        },
      })
      if (response.result === 'success') {
        toast({
          title: 'Success',
          description: 'Boot order set successfully',
        })
      } else {
        toast({
          title: 'Error',
          description: response.message || 'Failed to set boot order',
          variant: 'destructive',
        })
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to set boot order',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  // Function to insert ISOs
  const handleInsertIso = async () => {
    setLoading(true)
    try {
      const response = await api({
        method: 'POST',
        url: `/server/${service.id}/insertIso`,
        data: {
          iso: primaryISO || null,
          sec_iso: secondaryISO || null,
        },
      })
      if (response.result === 'success') {
        toast({
          title: 'Success',
          description: 'ISOs inserted successfully',
        })
      } else {
        toast({
          title: 'Error',
          description: response.message || 'Failed to insert ISOs',
          variant: 'destructive',
        })
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to insert ISOs',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogTrigger asChild>
          <Button
            variant="outline"
            size="sm"
            disabled={loading || !server.status || server.locked}
          >
            <UploadIcon className="w-4 h-4 mr-2" />
            Custom ISO
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent className="max-w-xl w-full">
          <AlertDialogHeader>
            <AlertDialogTitle>Custom ISO</AlertDialogTitle>
          </AlertDialogHeader>
          {/* Replaced AlertDialogDescription with a div to fix nesting error */}
          <div className="text-foreground">
            <div className="flex flex-col gap-4">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">Your ISOs</h2>
                <Button size="sm" onClick={() => setAddIsoOpen(true)}>
                  <PlusIcon className="w-4 h-4 mr-2" />
                  Add ISO
                </Button>
              </div>
              {loading ? (
                <p>Loading...</p>
              ) : isoList.length > 0 ? (
                <ul className="space-y-2">
                  {isoList.map((iso) => (
                    <li
                      key={iso}
                      className="flex justify-between items-center border p-2 rounded-md"
                    >
                      <span className="break-all">{iso}</span>
                      <div className="flex items-center gap-2">
                        <Button
                          size="icon"
                          variant="destructive"
                          onClick={() => onDeleteIso(iso)}
                        >
                          <TrashIcon className="w-4 h-4" />
                        </Button>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No ISOs found. Please add an ISO.</p>
              )}

              {/* Boot Order Settings */}
              <div className="flex flex-col gap-2 mt-4">
                <h2 className="text-lg font-semibold">Boot Order</h2>
                <RadioGroup
                  value={bootOrder}
                  onValueChange={(value) => setBootOrder(value)}
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="cda" id="boot-order-cda" />
                    <Label htmlFor="boot-order-cda">
                      1: Hard Disk 2: CD Drive
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="dca" id="boot-order-dca" />
                    <Label htmlFor="boot-order-dca">
                      1: CD Drive 2: Hard Disk
                    </Label>
                  </div>
                </RadioGroup>
                <Button onClick={handleSetBootOrder} disabled={loading}>
                  Set Boot Order
                </Button>
              </div>

              {/* ISO Selection */}
              <div className="flex flex-col gap-2 mt-4">
                <h2 className="text-lg font-semibold">Select ISOs</h2>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col">
                    <Label>Primary ISO</Label>
                    <Select
                      value={primaryISO}
                      onValueChange={(value) => setPrimaryISO(value)}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select ISO" />
                      </SelectTrigger>
                      <SelectContent>
                        {isoList.map((iso) => (
                          <SelectItem key={iso} value={iso}>
                            {iso}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex flex-col">
                    <Label>Secondary ISO</Label>
                    <Select
                      value={secondaryISO}
                      onValueChange={(value) => setSecondaryISO(value)}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select ISO" />
                      </SelectTrigger>
                      <SelectContent>
                        {isoList.map((iso) => (
                          <SelectItem key={iso} value={iso}>
                            {iso}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <Button onClick={handleInsertIso} disabled={loading}>
                  Insert ISOs
                </Button>
              </div>
            </div>
          </div>
          <AlertDialogFooter>
            <AlertDialogCancel>Close</AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Add ISO Dialog */}
      <Dialog open={addIsoOpen} onOpenChange={setAddIsoOpen}>
        <DialogContent className="max-w-lg">
          <DialogHeader>
            <DialogTitle>Add Custom ISO</DialogTitle>
            <DialogDescription>
              Enter the URL of the ISO you want to add.
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={handleSubmit(onAddIso)} className="space-y-4">
              <FormField
                control={control}
                name="url"
                rules={{ required: 'ISO URL is required' }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>ISO URL</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="https://example.com/your-custom.iso"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-end">
                <Button type="submit" disabled={loading}>
                  Add ISO
                </Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CustomISOButton