import React, { useEffect, useState, useMemo } from 'react'
import DashboardLayout from '../../components/DashboardLayout'
import api from '../../api/api'
import { Link } from 'react-router-dom'
import {
  CardDescription,
  CardHeader,
  CardTitle,
  Card,
  CardContent,
} from '../../components/ui/card'
import { toast } from '../../components/ui/use-toast'
import { listGPUs } from '../../lib/contentful'
import nvidia from '../../assets/nvidia.png'
import Loader from '../../components/ui/loader'
import { Badge } from '../../components/ui/badge'

const OrderList = () => {
  const [productGroups, setProductGroups] = useState([])
  const [loading, setLoading] = useState(false)
  const [gpuContent, setGPUContent] = useState([])

  useEffect(() => {
    setLoading(true)
    api(
      {
        method: 'GET',
        url: '/billing/productGroups',
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        let groups = data.productGroups.map((group) => {
          group.maxStock = group.products.reduce((acc, product) => {
            return Math.max(acc, product.stocklevel)
          }, 0)
          return group
        })
        .sort((a, b) => b.maxStock - a.maxStock)
        setProductGroups(groups)
      },
    )
  }, [])

  useEffect(() => {
    listGPUs().then((data) => {
      setGPUContent(data)
    })
  }, [])

  return (
    <DashboardLayout
      page={'Order'}
      crumbs={[
        { text: 'Dashboard', href: '/home' },
        { text: 'Order', href: '/order' },
      ]}
      content={
        <Card>
          <CardHeader>
            <CardTitle>Order Services</CardTitle>
            <CardDescription>Order new products and services</CardDescription>
          </CardHeader>
          <CardContent>
            {loading ? (
              <Loader />
            ) : (
              <ProductsTable
                productGroups={productGroups}
                gpuContent={gpuContent}
              />
            )}
          </CardContent>
        </Card>
      }
    />
  )
}

const ProductsTable = ({ productGroups, gpuContent }) => {
  return (
    <div className="flex flex-col space-y-4">
      {productGroups.map((productGroup) => {
        let content = gpuContent.find(
          (item) => item.fields.gpuName === productGroup.gpu_name,
        )?.fields

        let isUniqueGpu =
          productGroups
            .filter((group) => group.gpu_name === productGroup.gpu_name)
            .length === 1

        return (
          <Link key={productGroup.gid} to={`/order/${productGroup.gid}`}>
            <Card className="hover:bg-muted">
              <CardContent className="pt-6 flex flex-row">
                <div className="flex-grow">
                  <div className="flex flex-row items-center">
                    <img src={nvidia} className="h-4 mr-2" />
                    <span className="text-lg font-semibold">
                      {productGroup.gpu_name} {!isUniqueGpu && `(${productGroup?.cpu_name})`}
                    </span>
                  </div>
                  <div className="ml-1 space-y-1 text-gray-500 text-sm">
                    <div>
                      {productGroup?.gpu_vram}GB {productGroup?.gpu_vram_type}{' '}
                      VRAM
                    </div>
                    <div>
                      {productGroup?.cpu_name} @ {productGroup?.cpu_clock}GHz
                    </div>
                    <div>{productGroup?.disk_type} SSDs</div>
                  </div>
                </div>
                <div>
                  <div className="flex flex-row justify-end mb-2">
                    {productGroup.maxStock <= 0 && (
                      <Badge variant="red">
                        Out of Stock
                      </Badge>
                    )}
                    {productGroup.maxStock > 0 && (
                      <Badge variant="blue">
                        Instant Setup - {productGroup.maxStock} GPUs
                      </Badge>
                    )}
                  </div>
                  <div
                    className={`flex flex-row justify-end ${productGroup.maxStock <= 0 && ' text-gray-500'}`}
                  >
                    Starting at $
                    {productGroup.products[0].pricing.monthly.toFixed(0)}/m
                  </div>
                </div>
              </CardContent>
            </Card>
          </Link>
        )
      })}
    </div>
  )
}

export default OrderList
