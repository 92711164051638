import React, { useEffect } from 'react'
import { Input } from '../../components/ui/input'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form'
import OSSelect from './OSSelect'
import SSHKeySelect from './SSHKeySelect'
import { useAuth } from '../../hooks/useAuth'

const ProductOptions = ({ form }) => {
  const { isAuthed } = useAuth()

  return (
    <>
      {form?.control && (
        <>
          <FormField
            control={form.control}
            name="hostname"
            render={({ field }) => (
              <FormItem className="mb-2">
                <FormLabel>Hostname</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Hostname" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {isAuthed && (
            <FormField
              control={form.control}
              name="sshkey"
              render={({ field }) => (
                <FormItem className="mb-2">
                  <FormLabel>SSH Key</FormLabel>
                  <FormControl>
                    <SSHKeySelect {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </>
      )}
    </>
  )
}

export default ProductOptions
