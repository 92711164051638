import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { useForm } from 'react-hook-form'
import { Button } from '../../components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../components/ui/dialog'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Input } from '../../components/ui/input'
import { Icons } from '../../components/ui/icon'
import { toast } from '../../components/ui/use-toast'
import { KeyRound, Trash2 } from 'lucide-react'

const SSHKeys = () => {
  const [keys, setKeys] = useState([])
  const [loading, setLoading] = useState(false)

  const reloadSSHKeys = () => {
    if (loading) return
    setLoading(true)
    api(
      {
        method: 'GET',
        url: '/sshkeys',
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description:
              data?.message || 'An error occurred while fetching keys.',
            variant: 'destructive',
          })
        }
        setKeys(data.ssh_keys)
      },
    )
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(reloadSSHKeys, [])

  if (loading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Public SSH Keys</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center justify-center h-40">
            <Icons.spinner className="h-9 w-9 animate-spin" />
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader className="pb-4">
        <div className="flex flex-row">
          <div className="grow">
            <CardTitle>Public SSH Keys</CardTitle>
            <CardDescription className="mt-1">
              These public key(s) will be available to be added to new servers.
            </CardDescription>
          </div>
          <AddSSHKey
            reloadSSHKeys={reloadSSHKeys}
            trigger={<Button>Add New Key</Button>}
          />
        </div>
      </CardHeader>
      <CardContent>
        {keys.length === 0 && (
          <div className="text-gray-500 h-40 flex items-center justify-center">
            No SSH keys found.
          </div>
        )}
        {keys.length > 0 && (
          <div className="grid grid-cols-2 gap-4 mt-4">
            {keys.map((sshKey) => (
              <SSHKeyItem
                key={sshKey.id}
                sshKey={sshKey}
                reloadSSHKeys={reloadSSHKeys}
              />
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  )
}

const SSHKeyItem = ({ sshKey, reloadSSHKeys }) => {
  const [loading, setLoading] = useState(false)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)

  const handleDelete = (event) => {
    event.preventDefault()
    if (loading) return
    setLoading(true)
    api(
      {
        method: 'DELETE',
        url: `/sshkeys/${sshKey.id}`,
      },
      (data) => {
        setLoading(false)
        setDeleteConfirmOpen(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description:
              data?.message || 'An error occurred while deleting key.',
            variant: 'destructive',
          })
        }
        toast({
          title: 'Key Deleted',
          description: 'SSH Key has been deleted successfully.',
        })
        reloadSSHKeys()
      },
    )
  }

  return (
    <Card>
      <CardContent className="pt-6">
        <div className="flex flex-row gap-4">
          <KeyRound size={32} />
          <div className="grow">
            <p className="text-sm font-medium leading-none">{sshKey.name}</p>
            <p className="text-sm text-muted-foreground">
              {sshKey.key.substring(0, 14)}
              ...
              {sshKey.key.substring(sshKey.key.length - 14)}
            </p>
          </div>

          <AlertDialog
            open={deleteConfirmOpen}
            onOpenChange={setDeleteConfirmOpen}
          >
            <AlertDialogTrigger>
              <Button size="icon" variant="secondary">
                <Trash2 />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Confirm Deletion</AlertDialogTitle>
              </AlertDialogHeader>
              <AlertDialogDescription>
                Are you sure you want to delete this SSH key?
              </AlertDialogDescription>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleDelete} asChild>
                  <Button variant="destructive" loading={loading}>
                    Delete
                  </Button>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </CardContent>
    </Card>
  )
}

const AddSSHKey = ({ reloadSSHKeys, trigger }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const formSchema = z.object({
    name: z.string().min(2, {
      message: 'Name must be at least 2 characters.',
    }),
    key: z.string().min(10, {
      message: 'Key must be at least 10 characters.',
    }),
  })

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      key: '',
    },
  })

  const onSubmit = (data) => {
    setLoading(true)
    api(
      {
        method: 'POST',
        url: '/sshkeys',
        data,
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data?.message || 'An error occurred while adding key.',
            variant: 'destructive',
          })
        }
        toast({
          title: 'Key Added',
          description: 'SSH Key has been added successfully.',
        })
        setOpen(false)
        reloadSSHKeys()
      },
    )
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader>
              <DialogTitle>Add New SSH Key</DialogTitle>
              <DialogDescription>
                You can use this SSH when provisioning new servers. The SSH key
                will not be added to existing servers.
              </DialogDescription>
            </DialogHeader>
            <div className="space-y-4 py-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Key Name</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="My SSH Key"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="key"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>SSH Public Key</FormLabel>
                    <FormControl>
                      <Input
                        id="key"
                        className="col-span-3"
                        placeholder="ssh-rsa AAA..."
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <Button
                variant="ghost"
                onClick={() => {
                  setOpen(false)
                  form.reset()
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button type="submit" className="w-24" loading={loading}>
                Add Key
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default SSHKeys
export { AddSSHKey }
