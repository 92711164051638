import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import { Helmet } from 'react-helmet'

const DashboardLayout = ({ page, content, crumbs }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false)

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen)

  return (
    <div className="grid min-h-screen w-full grid-cols-[1fr] md:grid-cols-[280px_1fr]">
      <Helmet>
        <title>{page} - PureGPU</title>
      </Helmet>
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        page={page}
      />
      <div className="flex flex-col bg-muted/40 justify-center items-center overflow-hidden">
        <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 max-w-screen-lg w-full m-auto">
          <Topbar crumbs={crumbs} toggleSidebar={toggleSidebar} />
          {content}
        </main>
      </div>
    </div>
  )
}

export default DashboardLayout
