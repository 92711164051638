import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { loadStripe } from '@stripe/stripe-js'
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Button } from '../../components/ui/button'
import { CreditCard, Trash2, Ellipsis, Check } from 'lucide-react'
import { toast } from '../../components/ui/use-toast'
import { Icons } from '../../components/ui/icon'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
} from '../../components/ui/dropdown-menu'
import { Badge } from '../../components/ui/badge'
import {
  Card,
  CardTitle,
  CardContent,
  CardHeader,
} from '../../components/ui/card'
import { useNavigate } from 'react-router-dom'

// Initialization of Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

const CreditCards = () => {
  const [creditCards, setCreditCards] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchCards = () => {
    if (loading) return
    setLoading(true)
    api(
      {
        method: 'GET',
        url: '/billing/stripe/cards',
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setCreditCards(data.payment_methods)
      },
    )
  }

  // Fetch credit cards from API
  useEffect(() => {
    fetchCards()
  }, [])

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle>Credit Cards</CardTitle>
          <AddCreditCardButton reloadCards={fetchCards} />
        </div>
      </CardHeader>
      <CardContent>
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <Icons.spinner className="h-9 w-9 animate-spin" />
          </div>
        ) : (
          <>
            {creditCards.length === 0 && (
              <div className="text-gray-500 h-40 flex items-center justify-center">
                No credit cards on file
              </div>
            )}
            <div className="grid grid-cols-2 gap-4">
              {creditCards.map((card, index) => (
                <CreditCardItem
                  card={card}
                  key={card.id}
                  isDefault={index === 0}
                  reloadCards={fetchCards}
                />
              ))}
            </div>
          </>
        )}
      </CardContent>
    </Card>
  )
}

const CreditCardItem = ({ card, isDefault, reloadCards }) => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [defaultConfirmOpen, setDefaultConfirmOpen] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [defaultLoading, setDefaultLoading] = useState(false)

  const setAsDefaultCard = (e) => {
    e.preventDefault()
    if (defaultLoading) return
    setDefaultLoading(true)
    api(
      {
        method: 'PUT',
        url: `/billing/stripe/cards/${card.id}/default`,
      },
      (data) => {
        setDefaultLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        toast({
          title: 'Success',
          description: 'Card set as default successfully.',
        })
        reloadCards()
      },
    )
  }

  const deleteCard = (e) => {
    e.preventDefault()
    if (deleteLoading) return
    setDeleteLoading(true)
    api(
      {
        method: 'DELETE',
        url: `/billing/stripe/cards/${card.id}`,
      },
      (data) => {
        setDeleteLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        toast({
          title: 'Success',
          description: 'Card deleted successfully.',
        })
        reloadCards()
        setDeleteConfirmOpen(false)
      },
    )
  }

  return (
    <Card>
      <CardContent className="pt-6 flex gap-6 items-center">
        <div className="flex">
          <CreditCard />
        </div>
        <div className="flex grow gap-6">
          <div className="w-full flex flex-col justify-start">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <span className="font-medium">
                  {card.card_type} <span>&#8226;&#8226;&#8226;&#8226;</span>{' '}
                  {card.card_last_four}
                </span>
                {isDefault && (
                  <Badge variant="outline">
                    <Check size={16} className="pr-1" />
                    Default
                  </Badge>
                )}
              </div>
            </div>
            <div className="mt-1 text-sm text-gray-500">
              Expires {card.expiry_date}
            </div>
          </div>
        </div>
        <div className="flex">
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Ellipsis />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {!isDefault && (
                <DropdownMenuItem onClick={setDefaultConfirmOpen}>
                  <Check className="mr-2 h-4 w-4" />
                  <span>Set Default</span>
                </DropdownMenuItem>
              )}
              <DropdownMenuItem onClick={setDeleteConfirmOpen}>
                <Trash2 className="mr-2 h-4 w-4" />
                <span>Delete</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <AlertDialog
          open={deleteConfirmOpen}
          onOpenChange={setDeleteConfirmOpen}
        >
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Delete Card ending in {card.card_last_four}
              </AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to delete this card?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction asChild onClick={deleteCard}>
                <Button variant="destructive" loading={deleteLoading}>
                  Delete
                </Button>
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        <AlertDialog
          open={defaultConfirmOpen}
          onOpenChange={setDefaultConfirmOpen}
        >
          <AlertDialogTrigger asChild></AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Set as Default Card</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to set this card as default?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction asChild onClick={setAsDefaultCard}>
                <Button variant="primary" loading={defaultLoading}>
                  Set Default
                </Button>
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </CardContent>
    </Card>
  )
}

const AddCreditCardButton = ({ reloadCards }) => {
  const [open, setOpen] = useState(false)
  const [user, setUser] = useState(null)
  const navigate = useNavigate()

  const fetchUser = () => {
    api(
      {
        method: 'GET',
        url: '/user',
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setUser(data.user)
      },
    )
  }

  const handleAddCard = () => {
    if (!user) return
    if (!user.info_complete) {
      toast({
        title: 'Error',
        description: 'Please complete your account information first.',
        variant: 'destructive',
      })
      navigate('/settings')
      return
    }
    setOpen(true)
  }

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <>
      <Button disabled={!user} onClick={handleAddCard}>
        Add Card
      </Button>
      {open && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-75">
          <div className="bg-white rounded-lg shadow-md p-6 max-w-md w-full">
            <h3 className="text-xl font-bold mb-4">Add Credit Card</h3>
            <Elements stripe={stripePromise}>
              <PaymentForm
                closeModal={() => setOpen(false)}
                reloadCards={reloadCards}
              />
            </Elements>
          </div>
        </div>
      )}
    </>
  )
}

const PaymentForm = ({ closeModal, reloadCards }) => {
  const [loading, setLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const formSchema = z.object({
    cardNumber: z
      .object({
        complete: z.boolean(),
        empty: z.boolean(),
        error: z.object({ message: z.string() }).optional(),
      })
      .refine(
        (val) => val.complete && !val.empty && !val.error,
        ({ empty, error }) => {
          if (empty) {
            return { message: 'Card number is required' }
          }
          return { message: error?.message || 'Invalid card number' }
        },
      ),
    expiryDate: z
      .object({
        complete: z.boolean(),
        empty: z.boolean(),
        error: z.object({ message: z.string() }).optional(),
      })
      .refine(
        (val) => val.complete && !val.empty && !val.error,
        ({ empty, error }) => {
          if (empty) {
            return { message: 'Expiry date is required' }
          }
          return { message: error?.message || 'Invalid expiry date' }
        },
      ),
    cvc: z
      .object({
        complete: z.boolean(),
        empty: z.boolean(),
        error: z.object({ message: z.string() }).optional(),
      })
      .refine(
        (val) => val.complete && !val.empty && !val.error,
        ({ empty, error }) => {
          if (empty) {
            return { message: 'CVC is required' }
          }
          return { message: error?.message || 'Invalid CVC' }
        },
      ),
  })

  const form = useForm({
    resolver: zodResolver(formSchema),
  })

  const handleSubmit = async () => {
    if (loading) return
    if (!stripe || !elements) return
    setLoading(true)
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    })

    if (error) {
      console.error(error)
      toast({
        title: 'Error',
        description: 'Failed to create payment method',
        variant: 'destructive',
      })
      return
    }

    api(
      {
        method: 'POST',
        url: '/billing/stripe/cards',
        data: {
          stripe_payment_method_id: paymentMethod.id,
        },
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        toast({
          title: 'Success',
          description: 'Card added successfully.',
        })
        closeModal()
        reloadCards()
      },
    )
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="cardNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel
                className="block font-medium mb-1"
                htmlFor="expiry-date"
              >
                Card Number
              </FormLabel>
              <FormControl>
                <CardNumberElement
                  {...field}
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="expiryDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel
                  className="block font-medium mb-1"
                  htmlFor="expiry-date"
                >
                  Expiry Date
                </FormLabel>
                <FormControl>
                  <CardExpiryElement
                    id="expiry-date"
                    {...field}
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="cvc"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="block font-medium mb-1" htmlFor="cvc">
                  CVC
                </FormLabel>
                <FormControl>
                  <CardCvcElement
                    id="cvc"
                    {...field}
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex gap-2 justify-end mt-8">
          <Button variant="ghost" onClick={closeModal} type="button">
            Cancel
          </Button>
          <Button type="submit" className="px-6" loading={loading}>
            Add Card
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default CreditCards
