import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { Button } from '../../components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog'
import { toast } from '../../components/ui/use-toast'
import { KeyRound, Trash2, Plus } from 'lucide-react'
import { Icons } from '../../components/ui/icon'
import { Input } from '../../components/ui/input'
import copy from 'copy-to-clipboard'

const APIKeys = () => {
  const [keys, setKeys] = useState([])
  const [loading, setLoading] = useState(false)

  const reloadAPIKeys = () => {
    if (loading) return
    setLoading(true)
    api(
      {
        method: 'GET',
        url: '/apikeys',
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description:
              data?.message || 'An error occurred while fetching API keys.',
            variant: 'destructive',
          })
        }
        setKeys(data.apiKeys)
      },
    )
  }

  useEffect(reloadAPIKeys, [])

  if (loading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>API Keys</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center justify-center h-40">
            <Icons.spinner className="h-9 w-9 animate-spin" />
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader className="pb-4">
        <div className="flex flex-row">
          <div className="grow">
            <CardTitle>API Keys</CardTitle>
            <CardDescription className="mt-1">
              Manage your API keys for accessing the API.
            </CardDescription>
          </div>
          <GenerateAPIKey
            reloadAPIKeys={reloadAPIKeys}
            trigger={
              <Button>
                <Plus className="mr-2" size={16} />
                Generate New API Key
              </Button>
            }
          />
        </div>
      </CardHeader>
      <CardContent>
        {keys.length === 0 && (
          <div className="text-gray-500 h-40 flex items-center justify-center">
            No API keys found.
          </div>
        )}
        {keys.length > 0 && (
          <div className="grid grid-cols-2 gap-4 mt-4">
            {keys.map((apiKey) => (
              <APIKeyItem
                key={apiKey.id}
                apiKey={apiKey}
                reloadAPIKeys={reloadAPIKeys}
              />
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  )
}

const APIKeyItem = ({ apiKey, reloadAPIKeys }) => {
  const [loading, setLoading] = useState(false)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)

  const handleDelete = (event) => {
    event.preventDefault()
    if (loading) return
    setLoading(true)
    api(
      {
        method: 'DELETE',
        url: '/apikeys',
        data: { key: apiKey.key },
      },
      (data) => {
        setLoading(false)
        setDeleteConfirmOpen(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description:
              data?.message || 'An error occurred while revoking API key.',
            variant: 'destructive',
          })
        }
        toast({
          title: 'API Key Revoked',
          description: 'API key has been revoked successfully.',
        })
        reloadAPIKeys()
      },
    )
  }

  return (
    <Card>
      <CardContent className="pt-6">
        <div className="flex flex-row gap-4">
          <KeyRound size={32} />
          <div className="grow">
            <p className="text-sm font-medium leading-none">API Key</p>
            <div className="flex items-center mt-2">
              <Input
                readOnly
                value={apiKey.key}
                onFocus={(e) => e.target.select()}
              />
              <Button
                variant="secondary"
                className="ml-2"
                onClick={() => {
                  copy(apiKey.key)
                  toast({
                    title: 'Copied',
                    description: 'API key copied to clipboard.',
                  })
                }}
              >
                Copy
              </Button>
            </div>
            <p className="text-sm text-muted-foreground mt-2">
              Created at: {new Date(apiKey.createdAt).toLocaleString()}
            </p>
          </div>

          <AlertDialog
            open={deleteConfirmOpen}
            onOpenChange={setDeleteConfirmOpen}
          >
            <AlertDialogTrigger>
              <Button size="icon" variant="secondary">
                <Trash2 />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Confirm Revocation</AlertDialogTitle>
              </AlertDialogHeader>
              <AlertDialogDescription>
                Are you sure you want to revoke this API key? This action cannot
                be undone.
              </AlertDialogDescription>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleDelete} asChild>
                  <Button variant="destructive" loading={loading}>
                    Revoke
                  </Button>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </CardContent>
    </Card>
  )
}

const GenerateAPIKey = ({ reloadAPIKeys, trigger }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleGenerate = (event) => {
    event.preventDefault()
    if (loading) return
    setLoading(true)
    api(
      {
        method: 'POST',
        url: '/apikeys',
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description:
              data?.message || 'An error occurred while generating API key.',
            variant: 'destructive',
          })
        }
        toast({
          title: 'API Key Generated',
          description: 'Your new API key has been generated.',
        })
        setOpen(false)
        reloadAPIKeys()
      },
    )
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>{trigger}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Generate New API Key</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
          Are you sure you want to generate a new API key?
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button
              type="button"
              className="w-36"
              loading={loading}
              onClick={handleGenerate}
            >
              Generate Key
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default APIKeys
