import { useNavigate, useParams } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'
import api, { isAuthed } from '../../api/api'
import DashboardLayout from '../../components/DashboardLayout'
import ProductSpec from './ProductSpec.js'
import ProductOptions from './ProductOptions'
import ProductBilling from './ProductBilling'
import {
  Card,
  CardContent,
  CardTitle,
  CardHeader,
} from '../../components/ui/card'
import { Button } from '../../components/ui/button'
import { toast } from '../../components/ui/use-toast'
import GroupSpecs from './GroupSpecs.js'
import Loader from '../../components/ui/loader'
import { CheckIcon } from 'lucide-react'
import { useAuth } from '../../hooks/useAuth'
import LoginOptions from '../../pages/OrderConfirm/LoginOptions'
import { Form } from '../../components/ui/form'
import { useForm } from 'react-hook-form'
import OSSelect from './OSSelect.js'

const OrderConfirm = () => {
  const [productGroup, setProductGroup] = useState()
  const [product, setProduct] = useState()
  const [options, setOptions] = useState({
    billingcycle: 'monthly',
  })
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const { groupId } = useParams()
  const navigate = useNavigate()
  const { isAuthed } = useAuth()

  const form = useForm()
  const fields = form.watch()
  const prevFieldsRef = useRef()

  useEffect(() => {
    if (JSON.stringify(prevFieldsRef.current) !== JSON.stringify(fields)) {
      setOptions({ ...options, ...fields })
      prevFieldsRef.current = fields
    }
  }, [fields])

  useEffect(() => {
    setLoading(true)
    api(
      {
        method: 'GET',
        url: `/billing/productGroup/${groupId}`,
      },
      (data) => {
        setLoading(false)

        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }

        setProductGroup(data.productGroup)

        for (let product of data.productGroup?.products || []) {
          if (product.stocklevel > 0) {
            setProduct(product)
            break
          }
        }
      },
    )
  }, [groupId])

  const onSubmit = () => {
    setSubmitLoading(true)
    api(
      {
        method: 'POST',
        url: '/billing/orders',
        data: {
          pid: product.pid,
          billingcycle: options.billingcycle,
          hostname: options.hostname,
          os: options.os?.name,
          sshkey: options.sshkey,
        },
      },
      (data) => {
        setSubmitLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }

        toast({
          title: 'Success',
          description: 'Order placed',
        })

        if (options.billingcycle === 'hourly') {
          navigate(`/service/${data.serviceids}`)
        } else {
          navigate(`/billing/invoice/${data.invoiceid}`)
        }
      },
    )
  }

  return (
    <DashboardLayout
      page={'Order'}
      crumbs={[
        { text: 'Dashboard', href: '/home' },
        { text: 'Order', href: '/order' },
        { text: 'Order Confirmation' },
      ]}
      content={
        <Card>
          <CardHeader>
            <CardTitle>Order Confirmation</CardTitle>
          </CardHeader>
          <CardContent>
            {loading ? (
              <Loader />
            ) : (
              <>
                <GroupSpecs meta={productGroup} />
                <hr className="mt-6 mb-8" />
                <div className="scroll-m-20 text-2xl font-semibold tracking-tight mb-8">
                  Product Spec
                </div>
                <ProductSpec
                  product={product}
                  productGroup={productGroup}
                  setProduct={setProduct}
                />
                {product && (
                  <>
                    <Form {...form}>
                      <form>
                        <hr className="mt-10 mb-8" />
                        <div className="scroll-m-20 text-2xl font-semibold tracking-tight mb-8">
                          Billing Cycle
                        </div>
                        <ProductBilling
                          product={product}
                          options={options}
                          setOptions={setOptions}
                        />
                        <hr className="mt-10 mb-8" />
                        <div className="scroll-m-20 text-2xl font-semibold tracking-tight mb-8">
                          Operating System
                        </div>
                        <OSSelect
                          selectedOs={options.os}
                          setValue={(option) =>
                            setOptions({ ...options, os: option })
                          }
                        />
                        <hr className="mt-10 mb-8" />
                        <div className="scroll-m-20 text-2xl font-semibold tracking-tight mb-8">
                          Product Configuration
                        </div>
                        <ProductOptions
                          product={product}
                          options={options}
                          setOptions={setOptions}
                          form={form}
                          fields={fields}
                        />
                        
                        {isAuthed && (
                          <Button
                            className="mt-4"
                            loading={submitLoading}
                            onClick={onSubmit}
                          >
                            Order Server
                            <CheckIcon className="ml-1" />
                          </Button>
                        )}
                      </form>
                    </Form>
                    {!isAuthed && (
                      <>
                        <hr className="mt-10 mb-8" />
                        <div className="scroll-m-20 text-2xl font-semibold tracking-tight mb-3">
                          Account Setup
                        </div>
                        <LoginOptions />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </CardContent>
        </Card>
      }
    />
  )
}

export default OrderConfirm
