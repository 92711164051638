import api from '../../api/api'
import React, { useState } from 'react'
import { Button } from '../../components/ui/button'
import { useNavigate } from 'react-router-dom'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog'
import { toast } from '../../components/ui/use-toast'

const CancelButton = ({ service }) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleCancel = () => {
    setLoading(true)
    api(
      {
        method: 'POST',
        url: `/services/${service.id}/cancel`,
      },
      (data) => {
        setLoading(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        toast({
          title: 'Success',
          description: 'Service has been cancelled',
        })
        navigate('/services')
      },
    )
  }

  return service.pending_cancel ? (
    <Button variant="destructive" className="w-full">
      Cancellation Pending
    </Button>
  ) : (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild className="mt-2">
        <Button variant="outline" className="w-full">
          Cancel Service
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Cancel Confirmation</AlertDialogTitle>
          <AlertDialogDescription>
            On demand services will be immediately terminated. Services on
            commitment will terminate at the end of their billing cycle and not
            be sent future invoices. Are you sure you would like to cancel this
            service?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <Button onClick={handleCancel} loading={loading}>
            Cancel Service
          </Button>
          <AlertDialogCancel>Close</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default CancelButton
