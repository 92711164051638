import { Card, CardContent } from '../../components/ui/card'
import prettyBytes from 'pretty-bytes'

const ServiceSpecs = ({ service }) => {
  return (
    <Card>
      <CardContent className="grid gap-4 pt-6">
        <div className="grid grid-cols-[60px_1fr] items-center gap-2">
          <div className="font-medium">GPU</div>
          <div>
            {service.meta.gpu_count}x {service.meta.gpu_manufacturer}{' '}
            {service.meta.gpu_name} {service.meta.gpu_vram}GB{' '}
            {service.meta.gpu_vram_type}
          </div>
        </div>
        <div className="grid grid-cols-[60px_1fr] items-center gap-2">
          <div className="font-medium">CPU</div>
          <div>
            {service.meta.cpu_cores}x {service.meta.cpu_name} @{' '}
            {service.meta.cpu_clock}GHz
          </div>
        </div>

        <div className="grid grid-cols-[60px_1fr] items-center gap-2">
          <div className="font-medium">RAM</div>
          <div>
            {prettyBytes(parseInt(service.meta.ram) * Math.pow(10, 9))}{' '}
            {service.meta.ram_type}
          </div>
        </div>

        <div className="grid grid-cols-[60px_1fr] items-center gap-2">
          <div className="font-medium">Disk</div>
          <div>
            {prettyBytes(parseInt(service.meta.disk_space) * Math.pow(10, 9))}{' '}
            {service.meta.disk_type} SSD
          </div>
        </div>

        <div className="grid grid-cols-[60px_1fr] items-center gap-2">
          <div className="font-medium">Port</div>
          <div>10Gbps Network</div>
        </div>
      </CardContent>
    </Card>
  )
}

export default ServiceSpecs
