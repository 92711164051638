import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from './ui/breadcrumb'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from './ui/dropdown-menu'
import React from 'react'
import { CircleUser, Menu } from 'lucide-react'
import { Button } from './ui/button'
import { Link } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

const items = [
  {
    name: 'Settings',
    path: '/settings',
    auth: true,
  },
  {
    name: 'Support',
    path: '/support',
    auth: true,
  },
  {
    name: 'Support',
    path: '/support/ticketCreate',
    auth: false,
  },
  {
    name: 'Logout',
    path: '/logout',
    auth: true,
  },
  {
    name: 'Login',
    path: '/login',
    auth: false,
  },
  {
    name: 'Register',
    path: '/register',
    auth: false,
  },
]

const Topbar = ({ crumbs, toggleSidebar }) => {
  const { isAuthed } = useAuth()

  return (
    <div className="flex flex-row items-center">
      <button onClick={toggleSidebar} className="md:hidden block mr-3">
        <Menu className="h-6 w-6" />
      </button>
      <div className="grow">
        <Breadcrumb>
          <BreadcrumbList>
            {(crumbs || []).map((crumb, index) => (
              <React.Fragment key={index}>
                <Link to={crumb.href}>
                  <BreadcrumbItem>{crumb.text}</BreadcrumbItem>
                </Link>
                {index !== crumbs.length - 1 && <BreadcrumbSeparator />}
              </React.Fragment>
            ))}
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="secondary" size="icon" className="rounded-full">
            <CircleUser className="h-5 w-5" />
            <span className="sr-only">Toggle user menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {items.map((item) => {
            if (typeof item.auth === 'boolean' && item.auth !== isAuthed)
              return null
            return (
              <Link to={item.path} key={item.name}>
                <DropdownMenuItem>{item.name}</DropdownMenuItem>
              </Link>
            )
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default Topbar
