import React, { useState } from 'react'
import api from '../../api/api'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from '../../components/ui/card'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog'
import { Button } from '../../components/ui/button'
import { toast } from '../../components/ui/use-toast'
import AutoTopUp from '../../pages/Billing/AutoTopUp.js'

const topUpOptions = [25, 50, 100]

const TopUp = () => {
  const [open, setOpen] = useState(false)
  const [amount, setAmount] = useState(0)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleOpen = (value) => {
    console.log(value)
    if (value === 0) return
    setAmount(value)
    setOpen(true)
  }

  const handleTopup = (event) => {
    event.preventDefault()
    if (loading) return
    setLoading(true)
    api(
      {
        method: 'POST',
        url: '/billing/invoices/funds',
        data: {
          amount,
        },
      },
      (data) => {
        setLoading(false)
        setOpen(false)
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        navigate('/billing/invoice/' + data.invoice_id)
      },
    )
  }

  return (
    <Card className="w-full sm:w-3/4">
      <CardHeader>
        <CardTitle>Top Up Balance</CardTitle>
        <CardDescription>
          Add funds to your account to pay for services
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col space-y-4">
          <div className="grid grid-cols-2 gap-2 md:grid-cols-4 md:space-x-4 md:gap-0">
            {topUpOptions.map((option) => (
              <Button
                key={option}
                variant="default"
                className="px-8 rounded-full"
                onClick={() => handleOpen(option)}
              >
                ${option}
              </Button>
            ))}
            <AutoTopUp />
          </div>

          <AlertDialog open={open} onOpenChange={setOpen}>
            <div className="modal">
              <div className="modal-box">
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Top Up Balance</AlertDialogTitle>
                    <AlertDialogDescription>
                      Are you sure you want to top up ${amount}?
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel className="w-20">
                      Close
                    </AlertDialogCancel>
                    <AlertDialogAction onClick={handleTopup} asChild>
                      <Button variant="destructive" loading={loading}>
                        Top Up
                      </Button>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </div>
            </div>
          </AlertDialog>
        </div>
      </CardContent>
    </Card>
  )
}

export default TopUp
