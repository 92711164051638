import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { Controller } from 'react-hook-form'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select'
import { toast } from '../../components/ui/use-toast'
import { AddSSHKey } from '../../pages/Settings/SSHKeys'
import { Button } from '../../components/ui/button'

const SSHKeySelect = ({ name, control }) => {
  const [keys, setKeys] = useState([])

  const reloadSSHKeys = () => {
    api(
      {
        method: 'GET',
        url: '/sshkeys',
      },
      (data) => {
        if (data.result !== 'success' && data.result !== 'unauthenticated') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        let keys = data.ssh_keys
        // convert all ids in the array to strings
        keys = keys.map((key) => {
          key.id = key.id.toString()
          return key
        })
        setKeys(keys)
      },
      true,
    )
  }

  useEffect(reloadSSHKeys, [])

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <Select onValueChange={field.onChange} value={field.value}>
          <SelectTrigger>
            <SelectValue placeholder="Select SSH Keys" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {keys.length === 0 && (
                <SelectLabel>No SSH Keys Saved</SelectLabel>
              )}
              {keys.map((option) => (
                <SelectItem key={option.id} value={option.key}>
                  {`${option.name} (${option.key.substring(0, 25)}...)`}
                </SelectItem>
              ))}
              <AddSSHKey
                reloadSSHKeys={reloadSSHKeys}
                trigger={<Button className="m-3">Add a new SSH Key</Button>}
              />
            </SelectGroup>
          </SelectContent>
        </Select>
      )}
    />
  )
}

export default SSHKeySelect
