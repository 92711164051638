import CancelButton from './CancelButton'
import React from 'react'
import { Card, CardContent } from '../../components/ui/card'
import InvoicesButton from './InvoicesButton'
import { capitalizeFirstLetter } from '../../lib/utils'

const BillingInfo = ({ service }) => {
  return (
    <Card>
      <CardContent className="grid gap-4 pt-6">
        <div className="grid grid-cols-[120px_1fr] items-center gap-2">
          <div className="font-medium">Registered</div>
          <div>{service.regdate}</div>
        </div>
        <div className="grid grid-cols-[120px_1fr] items-center gap-2">
          <div className="font-medium">Billing Amount</div>
          <div>${service.billingamount}</div>
        </div>
        <div className="grid grid-cols-[120px_1fr] items-center gap-2">
          <div className="font-medium">Billing Cycle</div>
          <div>{capitalizeFirstLetter(service.billingcycle)}</div>
        </div>
        <div>
          {(service?.status === 'Active' ||
            service?.status === 'Suspended' ||
            service?.status === 'Pending') && (
            <CancelButton service={service} />
          )}
        </div>
        <InvoicesButton service={service} />
      </CardContent>
    </Card>
  )
}

export default BillingInfo
