import React, { useEffect, useRef, useState } from 'react'
import Autoplay from 'embla-carousel-autoplay'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../../components/ui/carousel'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import { Icons } from '../../components/ui/icon'
import { listAnnouncements } from '../../lib/contentful'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { file } = node.data.target.fields
      const imageUrl = file.url.startsWith('//')
        ? `https:${file.url}`
        : file.url
      return (
        <img
          src={imageUrl}
          width={file.details.image.width}
          height={file.details.image.height}
          className="mb-4 mt-2 max-h-[150px] object-cover rounded-md"
        />
      )
    },
  },
}

const Announcements = () => {
  const plugin = useRef(
    Autoplay({
      delay: 5000,
      stopOnInteraction: true,
    }),
  )
  const [announcementsData, setAnnouncementsData] = useState([])

  useEffect(() => {
    listAnnouncements().then((data) => {
      setAnnouncementsData(data)
    })
  }, [])

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center">
          <Icons.bell className="w-6 h-6 mr-2" />
          Announcements
        </CardTitle>
      </CardHeader>
      <CardContent className="flex items-center justify-center p-6 w-full">
        <Carousel
          plugins={[plugin.current]}
          className="w-full min-h-24 flex flex-row items-center justify-between"
          onMouseEnter={plugin.current.stop}
          onMouseLeave={plugin.current.play}
        >
          <CarouselPrevious className="relative left-0 transform-none" />
          <CarouselContent className="flex items-center p-4">
            {announcementsData.map((announcement, index) => (
              <CarouselItem
                key={index}
                className="flex flex-col justify-center items-center"
              >
                {documentToReactComponents(
                  announcement.fields.slideHeading,
                  renderOptions,
                )}
                {documentToReactComponents(
                  announcement.fields.slideContent,
                  renderOptions,
                )}
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselNext className="relative right-0 transform-none" />
        </Carousel>
      </CardContent>
    </Card>
  )
}

export default Announcements
